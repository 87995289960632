import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/partners/hero"
import Partnership from "components/sections/partners/partnership"
import Banner from "components/sections/common/banner"
import Tools from "components/sections/partners/tools"
import Updates from "components/sections/partners/updates"


const PartnersPage = ({ data }) => {
  const { frontmatter } = data.partners.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
     <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Partnership content={data.partnership.edges} />
        <Updates content={data.updates.edges} />
        <Tools content={data.tools.edges} />
        <Banner content={data.banner.edges} />
        <div className="gradient">

        </div>
        
      </Layout>
    </GlobalStateProvider>
  )
}


export default PartnersPage

export const pageQuery = graphql`
  {
    partners: allMdx(filter: { fileAbsolutePath: { regex: "/partners/fr/partners/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/partners/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            buttonText
            buttonLink
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
    partnership: allMdx(filter: { fileAbsolutePath: { regex: "/partners/fr/partnership/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
          }
          exports {
            shownItems
            items {
              name
            }
          }
        }
      }
    }
    updates: allMdx(filter: { fileAbsolutePath: { regex: "/partners/fr/updates/" } }) {
      edges {
        node {
          exports {
            items {
              description
              url
              buttonText
              image {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    banner: allMdx(filter: { fileAbsolutePath: { regex: "/partners/fr/banner/" } }) {
      edges {
        node {
          frontmatter {
            title
            buttonTitle
            link
            external
          }
        }
      }
    }
    tools: allMdx(filter: { fileAbsolutePath: { regex: "/partners/fr/tools/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            shownItems
            tools {
              alt
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
